const MaterialIcons = {
  close: `e5cd`,
  share: `e80d`,
  favorite: `e87d`,
  favorite_border: `e87e`,
  notifications: `e7f4`,
  notifications_off: `e7f6`,
  keyboard_arrow_up: `e316`,
  search: `e8b6`,
  arrow_drop_down: `e5c5`,
  arrow_right: `e5df`,
  access_time: `e192`,
  update: `e923`,
  tag: `e9ef`,
  home: `e88a`,
  comment: `e0b9`,
} as const;

export default MaterialIcons;

export const MaterialIconCodepoints = Object.values(MaterialIcons)
  .map((v) => String.fromCharCode(parseInt(`${v}`, 16)))
  .join("");
