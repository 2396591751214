import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "@/styles/main.scss";
import "@/utils/api/firebase";

import type { AppProps /*, AppContext */ } from "next/app";
import { Provider } from "jotai";
import MaterialIcons from "@/utils/material-icons";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Provider>
      <Component {...pageProps} />
      <style jsx global>
        {`
          .material-icons {
            &.mdi-close::before {
              content: "${"\\" + MaterialIcons.close}";
            }
            &.mdi-share::before {
              content: "${"\\" + MaterialIcons.share}";
            }
            &.mdi-favorite::before {
              content: "${"\\" + MaterialIcons.favorite}";
            }
            &.mdi-favorite_border::before {
              content: "${"\\" + MaterialIcons.favorite_border}";
            }
            &.mdi-notifications::before {
              content: "${"\\" + MaterialIcons.notifications}";
            }
            &.mdi-notifications_off::before {
              content: "${"\\" + MaterialIcons.notifications_off}";
            }
            &.mdi-keyboard_arrow_up::before {
              content: "${"\\" + MaterialIcons.keyboard_arrow_up}";
            }
            &.mdi-search::before {
              content: "${"\\" + MaterialIcons.search}";
            }
            &.mdi-arrow_drop_down::before {
              content: "${"\\" + MaterialIcons.arrow_drop_down}";
            }
            &.mdi-arrow_right::before {
              content: "${"\\" + MaterialIcons.arrow_right}";
            }
            &.mdi-access_time::before {
              content: "${"\\" + MaterialIcons.access_time}";
            }
            &.mdi-update::before {
              content: "${"\\" + MaterialIcons.update}";
            }
            &.mdi-tag::before {
              content: "${"\\" + MaterialIcons.tag}";
            }
            &.mdi-home::before {
              content: "${"\\" + MaterialIcons.home}";
            }
            &.mdi-comment::before {
              content: "${"\\" + MaterialIcons.comment}";
            }
          }
        `}
      </style>
    </Provider>
  );
}

export default MyApp;
